<template>
  <div>
    <c-search-box @enter="getList">
      <template slot="search">
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <c-plant type="search" name="plantCd" v-model="searchParam.plantCd" />
        </div>
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <c-select
            codeGroupCd="PSR_EQUIPMENT_STATUS_CD"
            type="search"
            itemText="codeName"
            itemValue="code"
            name="psrEquipmentStatusCd"
            label="설비상태"
            v-model="searchParam.psrEquipmentStatusCd"
          ></c-select>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <c-dept 
            type="search" 
            label="점검부서" 
            name="checkDeptCd" 
            :plantCd="searchParam.plantCd" 
            v-model="searchParam.checkDeptCd" />
        </div>
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <c-select
            stepperGrpCd="PSR_CHECK_STATUS_CD"
            type="search"
            itemText="stepperMstNm"
            itemValue="stepperMstCd"
            name="psrCheckStatusCd"
            label="진행단계"
            v-model="searchParam.psrCheckStatusCd"
          ></c-select>
        </div>
      </template>
    </c-search-box>
    <c-table
      ref="table"
      title="가동전 점검 목록"
      tableId="kras01"
      :columns="grid.columns"
      :data="grid.data"
      @linkClick="linkClick"
    >
      <!-- 버튼 영역 -->
      <template slot="table-button">
        <q-btn-group outline >
          <c-btn v-if="editable" label="등록" icon="add" @btnClicked="insertPop" />
          <c-btn label="검색" icon="search" @btnClicked="getList" />
        </q-btn-group>
      </template>
    </c-table>
    <c-dialog :param="popupOptions"></c-dialog>
  </div>
</template>

<script>
import selectConfig from '@/js/selectConfig';
export default {
  name: 'pre-startup-review',
  data() {
    return {
      searchParam: {
        plantCd: null,
        checkDeptCd: null,
        psrEquipmentStatusCd: null,
        psrCheckStatusCd: null,
      },
      grid: {
        columns: [
          {
            name: 'plantName',
            field: 'plantName',
            label: '사업장',
            align: 'center',
            style: 'width:100px',
            sortable: true,
          },
          {
            name: 'checkName',
            field: 'checkName',
            label: '점검명',
            align: 'left',
            style: 'width:250px',
            sortable: true,
            type: 'link',
          },
          {
            name: 'psrEquipmentStatusName',
            field: 'psrEquipmentStatusName',
            label: '설비상태',
            align: 'center',
            style: 'width:100px',
            sortable: true
          },
          {
            name: 'checkDeptName',
            field: 'checkDeptName',
            label: '점검부서',
            align: 'center',
            style: 'width:150px',
            sortable: true
          },
          {
            name: 'psrCheckStatusName',
            field: 'psrCheckStatusName',
            label: '진행단계',
            align: 'center',
            style: 'width:100px',
            sortable: true
          },
          {
            name: 'sopMocId',
            field: 'sopMocId',
            label: 'MOC번호',
            align: 'center',
            style: 'width:100px',
            sortable: true,
          },
          {
            name: 'impr',
            field: 'impr',
            label: '개선진행',
            align: 'center',
            sortable: false,
            child: [
              {
                name: 'actionCnt',
                field: 'actionCnt',
                label: '진행중인 건 수',
                align: 'right',
                type: 'cost',
                sortable: false,
                style: 'width:100px',
              },
              {
                name: 'completeCnt',
                field: 'completeCnt',
                label: '완료된 건 수',
                align: 'right',
                type: 'cost',
                sortable: false,
                style: 'width:100px',
              },
              {
                name: 'totalCnt',
                field: 'totalCnt',
                label: '총 건 수',
                align: 'right',
                type: 'cost',
                sortable: false,
                style: 'width:100px',
              },
            ]
          },
        ],
        data: [],
      },
      editable: true,
      listUrl: '',
      popupOptions: {
        isFull: true,
        target: null,
        title: '',
        visible: false,
        top: '',
        param: {},
        closeCallback: null,
      },
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      // role setting
      this.editable = this.$route.meta.editable;
      // url setting
      this.listUrl = selectConfig.sop.psr.check.list.url
      // code setting
      // list setting
      this.getList();
    },
    getList() {
      this.$http.url = this.listUrl;
      this.$http.type = 'GET';
      this.$http.param = this.searchParam;
      this.$http.request((_result) => {
        this.grid.data = _result.data;
      },);
    },
    /* eslint-disable no-unused-vars */
    linkClick(row, col, index) {
      this.insertPop(row);
    },
    insertPop(row) {
      this.popupOptions.title = '가동전점검 상세'; 
      this.popupOptions.param = {
        sopPrestartupCheckId: row ? row.sopPrestartupCheckId : '',
      };
      this.popupOptions.target = () => import(`${'./preStartupReviewDetail.vue'}`);
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closePopup;
    },
    closePopup() {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      this.getList();
    },
  }
};
</script>
